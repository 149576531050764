:root {
  --fp-text-color: #333;
  --fp-link-color: #fff;
  --fp-bg-body: hsl(52, 20%, 85%);
}

html {
  box-sizing: border-box;
}

body {
  background-color: var(--fp-bg-body);
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  margin: 0;
}

img {
  height: auto;
  max-width: 100%;
}

main {
  margin: 0 2vmax 0 2vmax;
  // overflow-x: hidden;
}

section {

  @media screen and (min-width: 60.25em) {
    min-height: 100vh;
    padding-bottom: 10vh;

    &:first-child {
      position: relative;

      &::after {
        content: '';
        width: 7px;
        height: calc(100% - 65vh);
        position: absolute;
        top: 0;
        right: calc((100%/3) + 4vmax);
        background-color: var(--fp-text-color);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        transform: translate(5vmin, 0);
      }
    }

  }

}

ul {
  padding-left: 0;
  list-style-type: none;

  > li {

    &:first-of-type {
      margin-bottom: 0;
      font-family: "nitti-typewriter-open", "coronette", sans-serif;
    }

    >strong {
      font-size: calc(var(--h3-font-size-max) * 1em);
      font-family: "nitti-typewriter-cameo", "coronette", Helvetica, Arial, sans-serif;
    }
  }
}

.icon {
  display: inline-block;
  height: 2.4rem;
  width: 2.4rem;
  margin-right: 5px;
  vertical-align: middle;
  fill: currentColor;
  pointer-events: none;
}

footer {
  a {
    // color: var(--fp-link-color);

    &:hover {
      text-decoration: none;
    }

    @include iconfont.icon('external', 'after') {
      font-size: 12px;
      vertical-align: 2px;
      margin-left: 2px;
    }
  }
}



.fp-main-title {
  @extend h1;
  // color: #ffc;
}

.section-active {
  header {
    position: sticky;
    top: 15vh;
  }
}

// .fp-active {
//   animation: title-fade .5s;
// }

// @keyframes title-fade {
//   from {
//     opacity: 0;
//   }

//   to {
//     opacity: 1;
//   }
// }
