.effect-c {
  opacity: 0.5;
  transition: opacity 0.3s cubic-bezier(0.5, 0, 0.5, 1);

  blockquote {
    position: relative;
    margin-top: 5vmax;
    z-index: 2;

    @media screen and (min-width: 43.75em) {
      margin-top: initial;
    }

    &:after {
      content: '';
      position: absolute;
    }
  }

  blockquote:after {
    opacity: 0;
    content: '⎦';
    font-size: 4em;
    line-height: 1;
    top: calc(100% + 20px);
    left: calc(100% + 20px);
    transform: translate(-50%, 50%) scale(0.8);
    transition: opacity 0.5s cubic-bezier(0.5, 0, 0.5, 1), transform 0.5s cubic-bezier(0.5, 0, 0.5, 1);
  }

  blockquote:before {
    position: absolute;
    opacity: 0;
    content: '⎡';
    font-size: 4em;
    line-height: 1;
    top: 20px;
    left: -20px;
    transform: translate(-50%, -150%) scale(0.8);
    transition: opacity 0.5s cubic-bezier(0.5, 0, 0.5, 1), transform 0.5s cubic-bezier(0.5, 0, 0.5, 1);
  }

  cite {
    display: block;
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 0.5s cubic-bezier(0.5, 0, 0.5, 1), transform 0.5s cubic-bezier(0.5, 0, 0.5, 1);


    @media screen and (min-width: 43.75em) {
      margin-top: initial;
      margin-bottom: 20vh;
    }
  }

  &.quote-active {
    opacity: 1;

    cite {
      transform: translateY(0);
      opacity: 1;
    }

    blockquote:after,
    blockquote:before {
      opacity: 0.1;
      transform: translate(-50%, -50%) scale(1);
    }
  }
}