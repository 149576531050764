.fp-section {
    &--work {
        >article {
            &:last-child {

                ol li {
                    margin-bottom: 1rem;
                }

                @media (min-width: 75em) {

                    div {
                        margin-bottom: 5vh;
                        display: grid;
                        grid-template-columns: 1fr 2fr;
                        
                        ul,
                        p {
                            margin: 0;
                            padding: 0;
                        }

                        ul {
                            display: grid;
                            grid-template-columns: 1fr 2fr;
                            grid-column: 1 / span 2;

                            li {
                                grid-column-start: 1;

                                &:first-child {
                                    font-size: 1.5em;
                                    align-self: end;
                                }
                                &:last-child {
                                    grid-column-start: 1;
                                }
                            }
                        }

                        li:has(h3) {
                            grid-column-start: 2;
                            // grid-row-start: 2;
                        }

                        p,
                        ol {
                            grid-column-start: 2;
                        }
                    }
                }
            }
        }
    }
}