@import url(https://use.typekit.net/pwr0hmi.css);
@font-face{font-family:"iconfont";src:url(/assets/_fonts/f8372852f94f06816610.woff2) format("woff2"),url(/assets/_fonts/eca9ecf618e921b5e179.woff) format("woff"),url(/assets/_fonts/557c35a2e56879765aea.ttf) format("truetype")}
[class^=iconfont-]::before,[class*=" iconfont-"]::before{font-family:"iconfont" !important;font-size:16px;font-style:normal;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;line-height:1}
.iconfont-external:before{content:""}
:root{--fp-text-color: #333;--fp-link-color: #fff;--fp-bg-body: hsl(52, 20%, 85%)}
html{box-sizing:border-box}
body{background-color:var(--fp-bg-body)}
*,*:before,*:after{box-sizing:border-box}
body{margin:0}
img{height:auto;max-width:100%}
main{margin:0 2vmax 0 2vmax}
@media screen and (min-width: 60.25em){section{min-height:100vh;padding-bottom:10vh}section:first-child{position:relative}section:first-child::after{content:"";width:7px;height:calc(100% - 65vh);position:absolute;top:0;right:calc(33.3333333333% + 4vmax);background-color:var(--fp-text-color);border-bottom-left-radius:5px;border-bottom-right-radius:5px;-webkit-transform:translate(5vmin, 0);transform:translate(5vmin, 0)}}
ul{padding-left:0;list-style-type:none}
ul>li:first-of-type{margin-bottom:0;font-family:"nitti-typewriter-open","coronette",sans-serif}
ul>li>strong{font-size:calc(var(--h3-font-size-max)*1em);font-family:"nitti-typewriter-cameo","coronette",Helvetica,Arial,sans-serif}
.icon{display:inline-block;height:2.4rem;width:2.4rem;margin-right:5px;vertical-align:middle;fill:currentColor;pointer-events:none}
footer a:hover{text-decoration:none}
footer a:after{display:inline-block;font-family:"iconfont";font-weight:normal;font-style:normal;font-variant:normal;text-decoration:none;text-transform:none;line-height:1;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;-webkit-user-select:none;user-select:none;content:"";font-size:12px;vertical-align:2px;margin-left:2px}
.section-active header{position:-webkit-sticky;position:sticky;top:15vh}
:root{--ec-ff-sans: "ubuntu", Helvetica, Arial, sans-serif;--ec-ff-serif: "ibm-plex-serif", "coronette", Helvetica, Arial, sans-serif;--bp-small: 24.15;--bp-medium: 43.75;--bp-large: 60.25;--bp-xlarge: 75;--text-wght: 350;--text-ital: 0;--p-line-height-min: 1.5;--p-line-height-max: 1.5;--p-font-size-min: 1.0;--p-font-size-max: 1.25;--p-vf-wght: var(--text-wght);--h1-line-height-min: .9;--h1-line-height-max: .95;--h1-font-size-min: 3;--h1-font-size-max: 8;--h1-vf-wght-multiplier-s: .33;--h1-vf-wght-multiplier-m: .33;--h1-vf-wght-multiplier-l: .33;--h2-line-height-min: 1.15;--h2-line-height-max: 1.25;--h2-font-size-min: 1.75;--h2-font-size-max: 2.5;--h2-vf-wght-multiplier-s: 1.25;--h2-vf-wght-multiplier-m: 1.5;--h2-vf-wght-multiplier-l: 1.75;--h3-line-height-min: 1.15;--h3-line-height-max: 1.25;--h3-font-size-min: 1.75;--h3-font-size-max: 2.5;--h3-vf-wght-multiplier-s: .5;--h3-vf-wght-multiplier-m: .5;--h3-vf-wght-multiplier-l: .5;--dc-line-height-min: .9;--dc-line-height-max: .95;--dc-font-size-min: 2.7;--dc-font-size-max: 5.25;--bq-line-height-min: 1.1;--bq-line-height-max: 1.1;--bq-font-size-min: 2.7;--bq-font-size-max: 5}
.italic,i,em{--text-ital: 1}
b,strong{--text-wght: 475;font-weight:700}
.clear{clear:both}
body,div,p,th,td,ul,li,h1,.fp-main-title,h2,h3,h4,h5,h6,blockquote,figcaption,cite,i,em,b,strong,button,p:first-line,p:first-letter{font-family:var(--ec-ff-sans);font-style:normal;font-variation-settings:"wght" var(--text-wght),"ital" var(--text-ital)}
p{--text-ital: 0;margin-left:auto;max-width:38rem;transition:all linear .2s;-webkit-hyphens:auto;hyphens:auto}
.fp-font-corrected{font-family:"nitti-typewriter-corrected","coronette",Helvetica,Arial,sans-serif}
p{font-size:calc(var(--p-font-size-min)*1rem);line-height:var(--p-line-height-min)}
@media screen and (min-width: 24.15em){p{line-height:calc(var(--p-line-height-min)*1em + (var(--p-line-height-max) - var(--p-line-height-min))*(100vw - var(--bp-small)*1em)/(var(--bp-large) - var(--bp-small)))}}
@media(min-width: 60.25em){p{font-size:calc(var(--p-font-size-min)*1em + (var(--p-font-size-max) - var(--p-font-size-min))*(100vw - var(--bp-large)*1em)/(var(--bp-xlarge) - var(--bp-large)));line-height:var(--p-line-height-max)}}
@media(min-width: 75em){p{font-size:calc(var(--p-font-size-max)*1em);max-width:32rem}}
h1,.fp-main-title,h2,h3,h4,h5,h6{font-family:var(--ec-ff-sans);font-weight:normal;transition:all linear .2s;--text-wght: calc(var(--p-vf-wght) * var(--h1-vf-wght-multiplier-s))}
h1,.fp-main-title{font-weight:800;--text-wght: calc(var(--p-vf-wght) * var(--h1-vf-wght-multiplier-s));--text-ital: 0;font-family:var(--ec-ff-serif);font-size:calc(var(--h1-font-size-min)*1em);line-height:var(--h1-line-height-min);margin:7vmax auto 2.5vmax;max-width:50rem}
@media screen and (min-width: 24.15em){h1,.fp-main-title{line-height:calc(var(--h1-line-height-min)*1em + (var(--h1-line-height-max) - var(--h1-line-height-min))*(100vw - var(--bp-small)*1em)/(var(--bp-xlarge) - var(--bp-small)));font-size:calc(var(--h1-font-size-min)*1em + (var(--h1-font-size-max) - var(--h1-font-size-min))*(100vw - var(--bp-small)*1em)/(var(--bp-xlarge) - var(--bp-small)))}}
@media screen and (min-width: 43.75em){h1,.fp-main-title{--text-wght: calc(var(--p-vf-wght) * var(--h1-vf-wght-multiplier-m));max-width:60rem;margin-top:15vh}}
@media screen and (min-width: 60.25em){h1,.fp-main-title{--text-wght: calc(var(--p-vf-wght) * var(--h1-vf-wght-multiplier-l));line-height:var(--h1-line-height-max);max-width:70rem}}
@media(min-width: 75em){h1,.fp-main-title{font-size:calc(var(--h1-font-size-max)*1em);line-height:var(--h1-line-height-max);max-width:75rem;margin:15vh auto 5vmin}}
h2{--text-wght: calc(var(--p-vf-wght) * var(--h2-vf-wght-multiplier-s));--text-ital: 0;font-family:"nitti-typewriter-cameo","coronette",Helvetica,Arial,sans-serif;font-size:calc(var(--h2-font-size-min)*1em);line-height:var(--h2-line-height-min);margin:1rem auto .5rem auto;max-width:38rem}
@media screen and (min-width: 24.15em){h2{line-height:calc(var(--h2-line-height-min)*1em + (var(--h2-line-height-max) - var(--h2-line-height-min))*(100vw - var(--bp-small)*1em)/(var(--bp-xlarge) - var(--bp-small)));font-size:calc(var(--h2-font-size-min)*1em + (var(--h2-font-size-max) - var(--h2-font-size-min))*(100vw - var(--bp-small)*1em)/(var(--bp-xlarge) - var(--bp-small)))}}
@media screen and (min-width: 43.75em){h2{--text-wght: calc(var(--p-vf-wght) * var(--h2-vf-wght-multiplier-m));max-width:38.5rem}}
@media screen and (min-width: 60.25em){h2{max-width:38.5rem}}
@media(min-width: 75em){h2{--text-wght: calc(var(--p-vf-wght) * var(--h2-vf-wght-multiplier-l));font-size:calc(var(--h2-font-size-max)*1em);line-height:var(--h2-line-height-max);max-width:42rem}}
h3{--text-wght: calc(var(--p-vf-wght) * var(--h3-vf-wght-multiplier-s));--text-ital: 1;color:#446;font-size:calc(var(--h3-font-size-min)*1em);line-height:var(--h3-line-height-min);margin:1rem auto .5rem auto;margin:0 auto .5rem auto;max-width:38rem}
@media screen and (min-width: 24.15em){h3{line-height:calc(var(--h3-line-height-min)*1em + (var(--h3-line-height-max) - var(--h3-line-height-min))*(100vw - var(--bp-small)*1em)/(var(--bp-xlarge) - var(--bp-small)));font-size:calc(var(--h3-font-size-min)*1em + (var(--h3-font-size-max) - var(--h3-font-size-min))*(100vw - var(--bp-small)*1em)/(var(--bp-xlarge) - var(--bp-small)))}}
@media screen and (min-width: 43.75em){h3{--text-wght: calc(var(--p-vf-wght) * var(--h3-vf-wght-multiplier-m));max-width:38.5rem}}
@media screen and (min-width: 60.25em){h3{max-width:38.5rem}}
@media(min-width: 75em){h3{--text-wght: calc(var(--p-vf-wght) * var(--h3-vf-wght-multiplier-l));font-size:calc(var(--h3-font-size-max)*1em);line-height:var(--h3-line-height-max);max-width:none}}
a{font-family:"nitti-typewriter-underlined","coronette",Helvetica,Arial,sans-serif}
a:link{color:#557;text-decoration:underline}
a:visited{color:#557;text-decoration:underline}
a:hover{color:#b44}
blockquote{line-height:var(--bq-line-height-min);font-style:italic;transition:all linear .2s;font-size:1em}
blockquote.right{float:right;margin-left:.5rem;margin-right:5%}
blockquote.left{float:left;margin-left:5%;margin-right:.5rem}
blockquote p{--text-wght: calc(var(--p-vf-wght) * .65);--text-ital: 1;font-size:6.5vw;margin:0 0 .25em 0;position:relative}
blockquote p:first-letter{--text-ital: 1}
blockquote cite,blockquote+cite{--text-ital: 1;display:block;text-align:right}
blockquote cite:before,blockquote+cite:before{content:"—"}
@media screen and (min-width: 24.15em){blockquote{font-size:2vw}blockquote.right{margin-left:.5rem;margin-right:0}blockquote.left{margin-left:0;margin-right:.5rem}blockquote p{--text-wght: calc(var(--p-vf-wght) * .8);font-size:5.5vw;line-height:calc(var(--bq-line-height-min)*1em + (var(--bq-line-height-max) - var(--bq-line-height-min))*(100vw - var(--bp-small)*1em)/(var(--bp-large) - var(--bp-small)))}blockquote cite,blockquote+cite{font-size:1em}}
@media(min-width: 60.25em){blockquote.right{margin-left:3.5%;margin-right:12vw}blockquote.left{margin-left:17%;margin-right:3.5%}blockquote p{--text-wght: calc(var(--p-vf-wght) * .7);font-size:4vw;line-height:var(--bq-line-height-max)}blockquote cite{font-size:1rem}}
h2+p{margin-top:1em}
li{font-size:calc(var(--p-font-size-min)*1rem)}
@media(min-width: 60.25em){li{font-size:calc(var(--p-font-size-min)*1em + (var(--p-font-size-max) - var(--p-font-size-min))*(100vw - var(--bp-large)*1em)/(var(--bp-xlarge) - var(--bp-large)))}}
@media(min-width: 75em){li{font-size:calc(var(--p-font-size-max)*1em)}}
article>p:first-of-type:first-line,article li p:first-line{font-weight:700;font-size:110%;--text-wght: calc(var(--p-vf-wght) * 1.25);font-family:var(--ec-ff-serif)}
@media screen and (min-width: 43.75em){article>p:first-of-type:first-line,article li p:first-line{--text-wght: calc(var(--p-vf-wght) * 1.25)}}
article header>p:first-of-type:first-letter{--text-wght: calc(var(--p-vf-wght) * 1.75);float:left;padding-right:.05em;font-family:var(--ec-ff-serif);font-size:calc(var(--dc-font-size-min)*1em);line-height:var(--dc-line-height-min);margin-bottom:-0.1em;margin-top:-0.05em}
@media screen and (min-width: 24.15em){article header>p:first-of-type:first-letter{font-size:calc(var(--dc-font-size-min)*1em + (var(--dc-font-size-max) - var(--dc-font-size-min))*(100vw - var(--bp-small)*1em)/(var(--bp-xlarge) - var(--bp-small)));line-height:calc(var(--dc-line-height-min)*1em + (var(--dc-line-height-max) - var(--dc-line-height-min))*(100vw - var(--bp-small)*1em)/(var(--bp-large) - var(--bp-small)))}}
@media screen and (min-width: 43.75em){article header>p:first-of-type:first-letter{--text-wght: calc(var(--p-vf-wght) * 1.95)}}
@media(min-width: 60.25em){article header>p:first-of-type:first-letter{--text-wght: calc(var(--p-vf-wght) * 2.15);font-size:calc(var(--dc-font-size-max)*1em);line-height:var(--dc-line-height-max)}}
@media screen and (min-width: 60.25em){section{display:grid;grid-template-columns:2fr 1fr;grid-auto-rows:-webkit-min-content;grid-auto-rows:min-content}section:nth-of-type(3),section:nth-of-type(4){grid-template-columns:1fr 1fr}section:nth-of-type(5) p{margin-left:0}section>*{grid-column-start:2}section>article{padding-left:4vmin;padding-right:4vmax}section>article:nth-child(odd){grid-column-start:1;margin-top:0}section>article:nth-child(even){margin-top:90vh}section:last-of-type>article:nth-child(even){margin-top:30vh}}
.fp-section--intro{margin-bottom:5vmax}
.fp-section--intro picture{float:left}
.fp-section--intro picture img{margin:0 auto;width:100%}
@media screen and (min-width: 33.75em){.fp-section--intro picture img{width:35vw;margin-right:1em}}
@media screen and (min-width: 60.25em){.fp-section--intro picture img{width:100%}}
.fp-section--intro h2{padding-top:5vmax}
.fp-section--skills{clear:both}
.fp-section--skills article:first-of-type{position:relative}
.fp-section--skills article:first-of-type::after{content:"";width:7px;height:calc(100% - 100vh);position:absolute;top:0;right:0;background-color:var(--fp-text-color);border-bottom-left-radius:5px;border-bottom-right-radius:5px;-webkit-transform:translate(0, 20%);transform:translate(0, 20%)}
.fp-section--contacts{position:relative}
.fp-section--contacts::before{font-size:50vh;color:rgba(0,0,0,.1);position:fixed;bottom:25vh;right:0;opacity:0;transition:opacity .75s}
.fp-section--contacts.section-active::before{opacity:1}
.fp-contact-list{font-size:1.5vw}
footer{width:100%;padding:5vh 2rem 1rem;text-align:left;background-color:var(--fp-bg-body)}
.fp-section--work>article:last-child ol li{margin-bottom:1rem}
@media(min-width: 75em){.fp-section--work>article:last-child div{margin-bottom:5vh;display:grid;grid-template-columns:1fr 2fr}.fp-section--work>article:last-child div ul,.fp-section--work>article:last-child div p{margin:0;padding:0}.fp-section--work>article:last-child div ul{display:grid;grid-template-columns:1fr 2fr;grid-column:1/span 2}.fp-section--work>article:last-child div ul li{grid-column-start:1}.fp-section--work>article:last-child div ul li:first-child{font-size:1.5em;align-self:end}.fp-section--work>article:last-child div ul li:last-child{grid-column-start:1}.fp-section--work>article:last-child div li:has(h3){grid-column-start:2}.fp-section--work>article:last-child div p,.fp-section--work>article:last-child div ol{grid-column-start:2}}
.grid-wrapper{max-width:35em;max-height:45em;width:50vw;display:grid;margin:auto;grid-template-columns:1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;grid-template-rows:1fr 1fr 1fr 1fr 1fr 1fr;grid-gap:1em;-webkit-transform:rotate(-45deg);transform:rotate(-45deg)}
.grid-wrapper .thing1{font-size:4em;text-transform:uppercase;grid-column:1/span 6;grid-row:2;align-self:end}
.grid-wrapper .thing2{font-size:2em;grid-column:2/span 2;grid-row:3}
.grid-wrapper .thing3{font-size:5em;grid-column:2;grid-row:4/span 3;align-self:end;-webkit-writing-mode:vertical-rl;writing-mode:vertical-rl}
.grid-wrapper .thing4{font-size:2em;grid-column:3/span 6;grid-row:6;align-self:start;text-align:right}
.grid-wrapper .thing4 span{display:block;text-transform:uppercase}
.grid-wrapper .thing5{font-size:1em;grid-column:8;grid-row:3/span 2;justify-self:start;-webkit-writing-mode:vertical-rl;writing-mode:vertical-rl}
.grid-wrapper .red-bar1{display:block;min-width:3em;width:100%;height:100%;background-color:red;grid-column:1;grid-row:3/span 4}
.grid-wrapper .red-bar2{display:block;width:100%;height:.5em;background-color:red;grid-column:3/span 6;grid-row:6;align-self:end;z-index:-1}
.grid-wrapper .red-circle{display:block;width:13em;height:13em;border-radius:100%;background-color:red;grid-column:4/span 3;grid-row:1/span 3;align-self:center;z-index:-1}
@media(max-width: 1024px){.grid-wrapper .thing1{font-size:3em}.grid-wrapper .thing2,.grid-wrapper .thing4{font-size:1.75em}.grid-wrapper .red-circle{width:10em;height:10em}}
@media(max-width: 768px){.grid-wrapper{-webkit-transform:rotate(0deg);transform:rotate(0deg);width:auto}}
@media(max-width: 570px){.grid-wrapper{grid-template-rows:1fr 1fr 1fr 1fr 1fr 1fr 1fr}.grid-wrapper .thing4{grid-column:1/span 8;grid-row:7}.grid-wrapper .red-bar2{grid-column:1/span 8;grid-row:7}}
@media(max-width: 425px){.grid-wrapper .thing1{font-size:2.75em}.grid-wrapper .thing3{font-size:4em}}
.effect-c{opacity:.5;transition:opacity .3s cubic-bezier(0.5, 0, 0.5, 1)}
.effect-c blockquote{position:relative;margin-top:5vmax;z-index:2}
@media screen and (min-width: 43.75em){.effect-c blockquote{margin-top:initial}}
.effect-c blockquote:after{content:"";position:absolute}
.effect-c blockquote:after{opacity:0;content:"⎦";font-size:4em;line-height:1;top:calc(100% + 20px);left:calc(100% + 20px);-webkit-transform:translate(-50%, 50%) scale(0.8);transform:translate(-50%, 50%) scale(0.8);transition:opacity .5s cubic-bezier(0.5, 0, 0.5, 1),-webkit-transform .5s cubic-bezier(0.5, 0, 0.5, 1);transition:opacity .5s cubic-bezier(0.5, 0, 0.5, 1),transform .5s cubic-bezier(0.5, 0, 0.5, 1);transition:opacity .5s cubic-bezier(0.5, 0, 0.5, 1),transform .5s cubic-bezier(0.5, 0, 0.5, 1),-webkit-transform .5s cubic-bezier(0.5, 0, 0.5, 1)}
.effect-c blockquote:before{position:absolute;opacity:0;content:"⎡";font-size:4em;line-height:1;top:20px;left:-20px;-webkit-transform:translate(-50%, -150%) scale(0.8);transform:translate(-50%, -150%) scale(0.8);transition:opacity .5s cubic-bezier(0.5, 0, 0.5, 1),-webkit-transform .5s cubic-bezier(0.5, 0, 0.5, 1);transition:opacity .5s cubic-bezier(0.5, 0, 0.5, 1),transform .5s cubic-bezier(0.5, 0, 0.5, 1);transition:opacity .5s cubic-bezier(0.5, 0, 0.5, 1),transform .5s cubic-bezier(0.5, 0, 0.5, 1),-webkit-transform .5s cubic-bezier(0.5, 0, 0.5, 1)}
.effect-c cite{display:block;opacity:0;-webkit-transform:translateY(100%);transform:translateY(100%);transition:opacity .5s cubic-bezier(0.5, 0, 0.5, 1),-webkit-transform .5s cubic-bezier(0.5, 0, 0.5, 1);transition:opacity .5s cubic-bezier(0.5, 0, 0.5, 1),transform .5s cubic-bezier(0.5, 0, 0.5, 1);transition:opacity .5s cubic-bezier(0.5, 0, 0.5, 1),transform .5s cubic-bezier(0.5, 0, 0.5, 1),-webkit-transform .5s cubic-bezier(0.5, 0, 0.5, 1)}
@media screen and (min-width: 43.75em){.effect-c cite{margin-top:initial;margin-bottom:20vh}}
.effect-c.quote-active{opacity:1}
.effect-c.quote-active cite{-webkit-transform:translateY(0);transform:translateY(0);opacity:1}
.effect-c.quote-active blockquote:after,.effect-c.quote-active blockquote:before{opacity:.1;-webkit-transform:translate(-50%, -50%) scale(1);transform:translate(-50%, -50%) scale(1)}
