/* FF Meta Variable Demo Font */
/*@font-face {
  font-family: 'FF Meta VF';
  src: url('../fonts/meta-vf/MetaVariableDemo-Set.woff2') format('woff2');
  font-display: swap;
  font-style: normal italic;
  font-weight: 100 900;
}*/

// @supports not (font-variation-settings: normal) {
//   @import url('https://fonts.googleapis.com/css?family=Ubuntu:400,400i,700,700i');
// }

@import url("https://use.typekit.net/pwr0hmi.css");

:root {

  --ec-ff-sans: "ubuntu", Helvetica, Arial, sans-serif;
  --ec-ff-serif: "ibm-plex-serif", "coronette", Helvetica, Arial, sans-serif;

  /* Breakpoint variables */
  --bp-small: 24.15;
  --bp-medium: 43.75;
  --bp-large: 60.25;
  --bp-xlarge: 75;

  /* initial values ital */
  --text-wght: 350;
  --text-ital: 0;

  /* Paragraph variables */
  --p-line-height-min: 1.5;
  --p-line-height-max: 1.5;
  --p-font-size-min: 1.0;
  --p-font-size-max: 1.25;
  --p-vf-wght: var(--text-wght);

  /* H1 variables */
  --h1-line-height-min: .9;
  --h1-line-height-max: .95;
  --h1-font-size-min: 3;
  --h1-font-size-max: 8;
  --h1-vf-wght-multiplier-s: .33;
  --h1-vf-wght-multiplier-m: .33;
  --h1-vf-wght-multiplier-l: .33;

  /* H2 variables */
  --h2-line-height-min: 1.15;
  --h2-line-height-max: 1.25;
  --h2-font-size-min: 1.75;
  --h2-font-size-max: 2.5;
  --h2-vf-wght-multiplier-s: 1.25;
  --h2-vf-wght-multiplier-m: 1.5;
  --h2-vf-wght-multiplier-l: 1.75;

  /* H3 variables */
  --h3-line-height-min: 1.15;
  --h3-line-height-max: 1.25;
  --h3-font-size-min: 1.75;
  --h3-font-size-max: 2.5;
  --h3-vf-wght-multiplier-s: .5;
  --h3-vf-wght-multiplier-m: .5;
  --h3-vf-wght-multiplier-l: .5;

  /* Dropcap variables */
  --dc-line-height-min: .9;
  --dc-line-height-max: .95;
  --dc-font-size-min: 2.7;
  --dc-font-size-max: 5.25;

  /* Blockquote variables */
  --bq-line-height-min: 1.1;
  --bq-line-height-max: 1.1;
  --bq-font-size-min: 2.7;
  --bq-font-size-max: 5;
}

.italic,
i,
em {
  --text-ital: 1;
}

b,
strong {
  --text-wght: 475;
  font-weight: 700;
}

.clear {
  clear: both;
}

body,
div,
p,
th,
td,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
figcaption,
cite,
i,
em,
b,
strong,
button,
p:first-line,
p:first-letter {
  font-family: var(--ec-ff-sans);
  //   font-family: "FF Meta VF", "coronette", Helvetica, Arial, sans-serif;
  // font-weight: normal;
  font-style: normal;
  font-variation-settings:
    'wght'var(--text-wght),
    'ital'var(--text-ital);
}

p {
  --text-ital: 0;
  margin-left: auto;
  // margin-right: auto;
  max-width: 38rem;
  transition: all linear 0.2s;
  hyphens: auto;
}

.fp-font-corrected {
  font-family: 'nitti-typewriter-corrected', "coronette", Helvetica, Arial, sans-serif;
}

/* Variations and variables */
p {
  font-size: calc(var(--p-font-size-min) * 1rem);
  line-height: var(--p-line-height-min);

  @media screen and (min-width: 24.15em) {
    line-height: calc((var(--p-line-height-min) * 1em) + (var(--p-line-height-max) - var(--p-line-height-min)) * ((100vw - (var(--bp-small) * 1em)) / (var(--bp-large) - var(--bp-small))));
  }

  @media (min-width: 60.25em) {
    font-size: calc((var(--p-font-size-min) * 1em) + (var(--p-font-size-max) - var(--p-font-size-min)) * ((100vw - (var(--bp-large) * 1em)) / (var(--bp-xlarge) - var(--bp-large))));
    line-height: var(--p-line-height-max);
  }

  @media (min-width: 75em) {
    font-size: calc(var(--p-font-size-max) * 1em);
    max-width: 32rem;
  }
}


/* Generic header styles */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--ec-ff-sans);
  font-weight: normal;
  transition: all linear 0.2s;
  --text-wght: calc(var(--p-vf-wght) * var(--h1-vf-wght-multiplier-s));
}

h1 {
  /* Set --text-wght value multiplier */
  font-weight: 800;
  // --h1-vf-wght-multiplier-l: .1;
  --text-wght: calc(var(--p-vf-wght) * var(--h1-vf-wght-multiplier-s));
  --text-ital: 0;

  font-family: var(--ec-ff-serif);
  font-size: calc(var(--h1-font-size-min) * 1em);
  line-height: var(--h1-line-height-min);
  margin: 7vmax auto 2.5vmax;
  max-width: 50rem;

  @media screen and (min-width: 24.15em) {
    line-height: calc((var(--h1-line-height-min) * 1em) + (var(--h1-line-height-max) - var(--h1-line-height-min)) * ((100vw - (var(--bp-small) * 1em)) / (var(--bp-xlarge) - var(--bp-small))));
    font-size: calc((var(--h1-font-size-min) * 1em) + (var(--h1-font-size-max) - var(--h1-font-size-min)) * ((100vw - (var(--bp-small) * 1em)) / (var(--bp-xlarge) - var(--bp-small))));
  }

  @media screen and (min-width: 43.75em) {
    --text-wght: calc(var(--p-vf-wght) * var(--h1-vf-wght-multiplier-m));
    max-width: 60rem;
    margin-top: 15vh;
  }

  @media screen and (min-width: 60.25em) {
    --text-wght: calc(var(--p-vf-wght) * var(--h1-vf-wght-multiplier-l));
    line-height: var(--h1-line-height-max);
    max-width: 70rem;
  }

  @media (min-width: 75em) {
    font-size: calc(var(--h1-font-size-max) * 1em);
    line-height: var(--h1-line-height-max);
    max-width: 75rem;
    margin: 15vh auto 5vmin;
  }
}

h2 {
  /* Set --text-wght value multiplier */
  --text-wght: calc(var(--p-vf-wght) * var(--h2-vf-wght-multiplier-s));
  --text-ital: 0;

  font-family: "nitti-typewriter-cameo", "coronette", Helvetica, Arial, sans-serif;
  font-size: calc(var(--h2-font-size-min) * 1em);
  line-height: var(--h2-line-height-min);
  margin: 1rem auto .5rem auto;
  max-width: 38rem;

  @media screen and (min-width: 24.15em) {
    line-height: calc((var(--h2-line-height-min) * 1em) + (var(--h2-line-height-max) - var(--h2-line-height-min)) * ((100vw - (var(--bp-small) * 1em)) / (var(--bp-xlarge) - var(--bp-small))));
    font-size: calc((var(--h2-font-size-min) * 1em) + (var(--h2-font-size-max) - var(--h2-font-size-min)) * ((100vw - (var(--bp-small) * 1em)) / (var(--bp-xlarge) - var(--bp-small))));
  }

  @media screen and (min-width: 43.75em) {
    --text-wght: calc(var(--p-vf-wght) * var(--h2-vf-wght-multiplier-m));

    max-width: 38.5rem;
  }

  @media screen and (min-width: 60.25em) {
    max-width: 38.5rem;
  }

  @media (min-width: 75em) {
    --text-wght: calc(var(--p-vf-wght) * var(--h2-vf-wght-multiplier-l));

    font-size: calc(var(--h2-font-size-max) * 1em);
    line-height: var(--h2-line-height-max);
    max-width: 42rem;
  }
}

h3 {
  /* Set --text-wght value multiplier */
  --text-wght: calc(var(--p-vf-wght) * var(--h3-vf-wght-multiplier-s));
  --text-ital: 1;

  color: #444466;
  // color: #fff;
  font-size: calc(var(--h3-font-size-min) * 1em);
  line-height: var(--h3-line-height-min);
  margin: 1rem auto .5rem auto;
  margin: 0 auto .5rem auto;
  max-width: 38rem;

  @media screen and (min-width: 24.15em) {
    line-height: calc((var(--h3-line-height-min) * 1em) + (var(--h3-line-height-max) - var(--h3-line-height-min)) * ((100vw - (var(--bp-small) * 1em)) / (var(--bp-xlarge) - var(--bp-small))));
    font-size: calc((var(--h3-font-size-min) * 1em) + (var(--h3-font-size-max) - var(--h3-font-size-min)) * ((100vw - (var(--bp-small) * 1em)) / (var(--bp-xlarge) - var(--bp-small))));
  }

  @media screen and (min-width: 43.75em) {
    --text-wght: calc(var(--p-vf-wght) * var(--h3-vf-wght-multiplier-m));

    max-width: 38.5rem;
  }

  @media screen and (min-width: 60.25em) {
    max-width: 38.5rem;
  }

  @media (min-width: 75em) {
    --text-wght: calc(var(--p-vf-wght) * var(--h3-vf-wght-multiplier-l));

    font-size: calc(var(--h3-font-size-max) * 1em);
    line-height: var(--h3-line-height-max);
    max-width: none;
  }
}

a {
  font-family: "nitti-typewriter-underlined", "coronette", Helvetica, Arial, sans-serif;

  &:link {
    color: #555577;
    text-decoration: underline;
  }

  &:visited {
    color: #555577;
    text-decoration: underline;
  }

  &:hover {
    color: #bb4444;

  }
}

blockquote {
  // float: right;
  line-height: var(--bq-line-height-min);
  // margin: 5vmin auto .5rem;
  font-style: italic;
  // max-width: 45%;
  transition: all linear 0.2s;
  font-size: 1em;

  &.right {
    float: right;
    margin-left: .5rem;
    margin-right: 5%;
  }

  &.left {
    float: left;
    margin-left: 5%;
    margin-right: .5rem;
  }

  p {
    /* Set --text-x value */
    --text-wght: calc(var(--p-vf-wght) * .65);
    --text-ital: 1;
    font-size: 6.5vw;
    margin: 0 0 .25em 0;
    position: relative;

    &:first-letter {
      --text-ital: 1;
    }
  }

  cite,
  + cite {
    --text-ital: 1;
    display: block;
    // font-size: 2.5vw;
    text-align: right;

    &:before {
      content: "—";
    }
  }

  @media screen and (min-width: 24.15em) {
    // margin: .5rem 5% .5rem .5rem;
    // max-width: 45%;
      font-size: 2vw;

    &.right {
      margin-left: .5rem;
      margin-right: 0;
    }

    &.left {
      margin-left: 0;
      margin-right: .5rem;
    }

    p {
      --text-wght: calc(var(--p-vf-wght) * .8);
      font-size: 5.5vw;
      line-height: calc((var(--bq-line-height-min) * 1em) + (var(--bq-line-height-max) - var(--bq-line-height-min)) * ((100vw - (var(--bp-small) * 1em)) / (var(--bp-large) - var(--bp-small))));
    }

    cite,
    + cite {
      font-size: 1em;
    }
  }

  @media (min-width: 60.25em) {
    // margin: 1rem 12vw 2rem 2rem;
    // max-width: 29.5%;

    &.right {
      margin-left: 3.5%;
      margin-right: 12vw;
    }

    &.left {
      margin-left: 17%;
      margin-right: 3.5%;
    }

    p {
      --text-wght: calc(var(--p-vf-wght) * .7);
      font-size: 4vw;
      line-height: var(--bq-line-height-max);
    }

    cite {
      font-size: 1rem;
    }
  }
}


h2+p {
  margin-top: 1em;
}

 li{
  font-size: calc(var(--p-font-size-min) * 1rem);

  @media (min-width: 60.25em) {
    font-size: calc((var(--p-font-size-min) * 1em) + (var(--p-font-size-max) - var(--p-font-size-min)) * ((100vw - (var(--bp-large) * 1em)) / (var(--bp-xlarge) - var(--bp-large))));
  }

  @media (min-width: 75em) {
    font-size: calc(var(--p-font-size-max) * 1em);
  }
 }

/* First-line styles */
article>p:first-of-type:first-line, article li p:first-line {
  /* Set --text-x value */
  font-weight: 700;
  font-size: 110%;
  --text-wght: calc(var(--p-vf-wght) * 1.25);

  font-family: var(--ec-ff-serif);

  @media screen and (min-width: 43.75em) {
    --text-wght: calc(var(--p-vf-wght) * 1.25);

  }
}

/* Initial Cap styles */
article header >p:first-of-type:first-letter {
  --text-wght: calc(var(--p-vf-wght) * 1.75);

  // color: #444466;
  // color: #fff;
  float: left;
  padding-right: 0.05em;
  font-family: var(--ec-ff-serif);
  font-size: calc(var(--dc-font-size-min) * 1em);
  line-height: var(--dc-line-height-min);
  margin-bottom: -.1em;
  margin-top: -.05em;

  @media screen and (min-width: 24.15em) {
    font-size: calc((var(--dc-font-size-min) * 1em) + (var(--dc-font-size-max) - var(--dc-font-size-min)) * ((100vw - (var(--bp-small) * 1em)) / (var(--bp-xlarge) - var(--bp-small))));
    line-height: calc((var(--dc-line-height-min) * 1em) + (var(--dc-line-height-max) - var(--dc-line-height-min)) * ((100vw - (var(--bp-small) * 1em)) / (var(--bp-large) - var(--bp-small))));
  }

  @media screen and (min-width: 43.75em) {
    --text-wght: calc(var(--p-vf-wght) * 1.95);

  }

  @media (min-width: 60.25em) {
    --text-wght: calc(var(--p-vf-wght) * 2.15);

    font-size: calc(var(--dc-font-size-max) * 1em);
    line-height: var(--dc-line-height-max);
  }
}
