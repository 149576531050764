.fp-section {
  &--intro {

    picture {
      float: left;

      img {
        margin: 0 auto;
        width: 100%;

        @media screen and (min-width: 33.75em) {
          width: 35vw;
          margin-right: 1em;
        }
        
        @media screen and (min-width: 60.25em) {
          width: 100%;
        }
      }
    }

    h2 {
      
      padding-top: 5vmax;
    }
    
    margin-bottom: 5vmax;
    // clear: both;
  }

  &--skills {
    clear: both;
    
    article:first-of-type {
      position: relative;

      &::after {
        content: '';
        width: 7px;
        height: calc(100% - 100vh);
        position: absolute;
        top: 0;
        right: 0;
        background-color: var(--fp-text-color);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        transform: translate(0, 20%);
      }
    }
  }

  &--referrals {}

  &--contacts {

    position: relative;

    &::before {
      // content: "« »";
      // content: "@";
      font-size: 50vh;
      color: rgba(0, 0, 0, 0.1);
      position: fixed;
      bottom: 25vh;
      right: 0;
      opacity: 0;
      transition: opacity .75s;
    }

    &.section-active {
      &::before {
        opacity: 1;
      }
    }
  }
}

.profile-tilted {
  img {
    // transform: rotate(-4deg);
  }
}

.fp-contact-list {
  font-size: 1.5vw;
}

footer {
  width: 100%;
  padding: 5vh 2rem 1rem;
  text-align: left;
  background-color: var(--fp-bg-body);
}