section {

  @media screen and (min-width: 60.25em) {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-auto-rows: min-content;

    &:nth-of-type(3),
    &:nth-of-type(4) {
      grid-template-columns: 1fr 1fr;
    }

    &:nth-of-type(5) {
      p {
        margin-left: 0;
      }
    }

    >* {
      grid-column-start: 2;
    }

    >article {
      padding-left: 4vmin;
      padding-right: 4vmax;

      &:nth-child(odd) {
        grid-column-start: 1;
        margin-top: 0;
      }

      &:nth-child(even) {
        margin-top: 90vh;
      }
    }

    &:last-of-type {
      >article {
        &:nth-child(even) {
          margin-top: 30vh;
        }
      }
    }
  }
}
